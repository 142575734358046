require('../../modules/es6.symbol');
require('../../modules/es6.object.create');
require('../../modules/es6.object.define-property');
require('../../modules/es6.object.define-properties');
require('../../modules/es6.object.get-own-property-descriptor');
require('../../modules/es6.object.get-prototype-of');
require('../../modules/es6.object.keys');
require('../../modules/es6.object.get-own-property-names');
require('../../modules/es6.object.freeze');
require('../../modules/es6.object.seal');
require('../../modules/es6.object.prevent-extensions');
require('../../modules/es6.object.is-frozen');
require('../../modules/es6.object.is-sealed');
require('../../modules/es6.object.is-extensible');
require('../../modules/es6.object.assign');
require('../../modules/es6.object.is');
require('../../modules/es6.object.set-prototype-of');
require('../../modules/es6.object.to-string');
require('../../modules/es7.object.get-own-property-descriptors');
require('../../modules/es7.object.values');
require('../../modules/es7.object.entries');
require('../../modules/es7.object.define-getter');
require('../../modules/es7.object.define-setter');
require('../../modules/es7.object.lookup-getter');
require('../../modules/es7.object.lookup-setter');
require('../../modules/core.object.is-object');
require('../../modules/core.object.classof');
require('../../modules/core.object.define');
require('../../modules/core.object.make');
module.exports = require('../../modules/_core').Object;
